import React from 'react';
import Switcher from "./Switcher";
import FormItemBudget from "./FormItemBudget";
import FormItemTerm from "./FormItemTerm";
import GuideStep from "./GuideStep";
import FormItemChoice from "./FormItemChoice";
import FormItem from "./FormItem";
import ThankYouPage from "./ThankYouPage";
import Loader from "../Loader";
import {useTranslation} from "react-i18next";


export default function GuideSteps() {

    // translation
    const {t, i18n} = useTranslation()

    // Calculate Delivery Date
    const calculateDeliveryDate = (event) => {
        let days = parseInt(event, 10);
        let currentDate = new Date();
        let deliveryDate = new Date(currentDate.getTime() + days * 24 * 60 * 60 * 1000);
        setDeliveryDate(deliveryDate);
        setDeliveryDays(event)
    };

    const defaultDeliveryDate = () => {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + Math.floor(t('guide.deliveryDate.to')/2));
        return currentDate;
    }

    // Setting states
    const currentLanguage = i18n.language.split('-')[0];

    const projectType = t('appType');
    const [businessField, setBusinessField] = React.useState('');
    const [prevBusinessField, setPrevBusinessField] = React.useState(''); // Used to compare whether the input data has been changed from the previous api call

    const [suggestedTargetGroups, setSuggestedTargetGroups] = React.useState('');
    const [selectedTargetGroups, setSelectedTargetGroups] = React.useState('');
    const [customTargetGroups, setCustomTargetGroups] = React.useState('');
    const combinedTargetGroups = customTargetGroups + (customTargetGroups === "" || selectedTargetGroups === "" ? "" : ", ") + selectedTargetGroups;
    const [prevTargetGroups, setPrevTargetGroups] = React.useState(''); // Used to compare whether the input data has been changed from the previous api call

    const [suggestedGoals, setSuggestedGoals] = React.useState('');
    const [selectedGoals, setSelectedGoals] = React.useState('');
    const [customGoals, setCustomGoals] = React.useState('');
    const combinedGoals = customGoals + (customGoals === "" || selectedGoals === "" ? "" : ", ") + selectedGoals;
    const [prevGoals, setPrevGoals] = React.useState(''); // Used to compare whether the input data has been changed from the previous api call

    const [suggestedMetrics, setSuggestedMetrics] = React.useState('');
    const [selectedMetrics, setSelectedMetrics] = React.useState('');
    const [customMetrics, setCustomMetrics] = React.useState('');
    const combinedMetrics = customMetrics + (customMetrics === "" || selectedMetrics === "" ? "" : ", ") + selectedMetrics;

    const [budget, setBudget] = React.useState(0);
    const [deliveryDate, setDeliveryDate] = React.useState(defaultDeliveryDate());
    const [deliveryDays, setDeliveryDays] = React.useState(Math.floor(t('guide.deliveryDate.to')/2));
    const [projectName, setProjectName] = React.useState('');
    const [companyName, setCompanyName] = React.useState('');
    const [personName, setPersonName] = React.useState('');
    const [userEmail, setUserEmail] = React.useState('');
    const [userIp, setUserIp] = React.useState('');
    const [step, setStep] = React.useState(1);
    const [submittedToWebhook, setSubmittedToWebhook] = React.useState(false);

    // Target Groups API call
    const getTargetGroups = async (event) => {
        if (prevBusinessField !== businessField) { // Resolves a possible change of combinedTargetGroups data when stepping backwards.
            setPrevBusinessField(businessField);
            setSuggestedTargetGroups("");
            stepNext(event);
            const response = await fetch(`/api?prompt=targetGroups&businessField=${businessField}&language=${currentLanguage}`);
            const responseData = await response.json();
            setSuggestedTargetGroups(responseData.chatResponse);
            setUserIp(responseData.userIp);
        }else {
            stepNext(event);
        }
    };

    const reloadTargetGroups = async (event) => {
        setSuggestedTargetGroups("");
        const response = await fetch(`/api?prompt=targetGroups&businessField=${businessField}&language=${currentLanguage}`);
        const responseData = await response.json();
        setSuggestedTargetGroups(responseData.chatResponse);
    };

    // Business Goals API call
    const getBusinessGoals = async (event) => {
        if (prevTargetGroups !== combinedTargetGroups) { // Resolves a possible change of combinedTargetGroups data when stepping backwards.
            setPrevTargetGroups(combinedTargetGroups);
            setSuggestedGoals("");
            stepNext(event);
            const response = await fetch(`/api?prompt=goals&businessField=${businessField}&targetGroups=${combinedTargetGroups}&language=${currentLanguage}`);
            const responseData = await response.json();
            setSuggestedGoals(responseData.chatResponse);
        }else {
            stepNext(event);
        }
    };

    const reloadBusinessGoals = async (event) => {
        setSuggestedGoals("");
        const response = await fetch(`/api?prompt=goals&businessField=${businessField}&targetGroups=${combinedTargetGroups}&language=${currentLanguage}`);
        const responseData = await response.json();
        setSuggestedGoals(responseData.chatResponse);
    };

    // Business Metrics API call
    const getBusinessMetrics = async (event) => {
        if (prevGoals !== combinedGoals) { // Resolves a possible change of combinedTargetGroups data when stepping backwards.
            setPrevGoals(combinedGoals);
            setSuggestedMetrics("");
            stepNext(event);
            const response = await fetch(`/api?prompt=metrics&businessGoals=${combinedGoals}&businessField=${businessField}&language=${currentLanguage}`);
            const responseData = await response.json();
            setSuggestedMetrics(responseData.chatResponse);
        }else {
            stepNext(event);
        }
    };

    const reloadBusinessMetrics = async (event) => {
        setSuggestedMetrics("");
        const response = await fetch(`/api?prompt=metrics&businessGoals=${combinedGoals}&businessField=${businessField}&language=${currentLanguage}`);
        const responseData = await response.json();
        setSuggestedMetrics(responseData.chatResponse);
    };

    // Build Array From String Chat Response
    const responseStringArray = (response) => {
        let string = response;
        string = string.replace(/<ul>|<\/ul>|<\/li>|<strong>|<\/strong>|[\n\t]| {2,}|[1-9]\. /g,"");
        string = string.split("<li>");
        string.shift();
        return string;
    };

    // Step Next Shift
    const stepNext = (event) => {
        event.preventDefault();
        setStep(step+1);
    };

    // Step Back Shift
    const stepBack = () => {
        setStep(step-1);
    };

    // Make.com connection
    const submitData = async (event) => {
        stepNext(event);

        // Send data by Webhook to make.com
        const webhookUrl = 'https://hook.eu1.make.com/fuubm278vw1ntkmidsldckrkjungo9qm';
        const data = {
            projectType: projectType,
            businessField: businessField,
            businessGoals: combinedGoals,
            businessMetrics: combinedMetrics,
            targetGroups: combinedTargetGroups,
            budget: budget,
            deliveryDate: deliveryDate,
            projectName: projectName,
            companyName: companyName,
            userName: personName,
            userEmail: userEmail,
            userIp: userIp,
            language: currentLanguage
        };
        const response = await fetch(webhookUrl, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        setSubmittedToWebhook(true);

        // Post goal event to Google Analytics
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': 'form_sent'});

    };

    // Business Field
    if(step == 1) {
        return(
            <>
                <GuideStep
                    submitHandle={getTargetGroups}
                    headline={t('guide.businessField.headline')}
                    desc={t('guide.businessField.desc')}
                    examples={t('guide.businessField.examples')}
                    allowSubmitOn={businessField}
                    stepHandle={{step, setStep}}
                >
                    <div className="form-list">
                        <div className="form-list__block">
                            <FormItem
                                type={"text"}
                                value={businessField}
                                handleChange={(event) => setBusinessField(event.target.value)}
                                label={t('guide.businessField.label')}
                                required={true}
                            />
                        </div>
                    </div>
                </GuideStep>
                <Switcher stepHandle={{step, setStep}} />
            </>
        )
    }
    // Target Groups
    else if (step == 2) {
        return(
            <>
                {!suggestedTargetGroups ?  <Loader />:
                    <>
                        <GuideStep
                            submitHandle={getBusinessGoals}
                            headline={t('guide.targetGroups.headline')}
                            desc={t('guide.targetGroups.desc')}
                            subheadline={t('guide.subHeadlineGeneral')}
                            buttonReload={reloadTargetGroups}
                            buttonBackHandle={stepBack}
                            allowSubmitOn={selectedTargetGroups || customTargetGroups}
                            stepHandle={{step, setStep}}
                        >
                            <div className="form-list">
                                <div className="form-list__block">
                                    {
                                        responseStringArray(suggestedTargetGroups).map((value, index) => (
                                            <FormItemChoice
                                                key={index}
                                                type="checkbox"
                                                name="businessField"
                                                value={value}
                                                label={value}
                                                box
                                                constants={{currentState : selectedTargetGroups, updateState : setSelectedTargetGroups}}
                                            />
                                        ))
                                    }
                                </div>
                                <div className="form-list__block">
                                    {
                                        <FormItem
                                            value={customTargetGroups}
                                            type={"text"}
                                            handleChange={(event) => setCustomTargetGroups(event.target.value)}
                                            label={t('guide.targetGroups.additionalLabel')}
                                        />
                                    }
                                </div>
                            </div>
                        </GuideStep>
                        <Switcher stepHandle={{step, setStep}} />
                    </>
                }
            </>
        )
    }
    // Business Goals
    else if (step == 3) {
        return(
            <>
                {!suggestedGoals ?  <Loader /> :
                    <>
                        <GuideStep
                            submitHandle={getBusinessMetrics}
                            headline={t('guide.businessGoals.headline')}
                            desc={t('guide.businessGoals.desc')}
                            subheadline={t('guide.subHeadlineGeneral')}
                            buttonReload={reloadBusinessGoals}
                            buttonBackHandle={stepBack}
                            allowSubmitOn={customGoals || selectedGoals}
                            stepHandle={{step, setStep}}
                        >
                            <div className="form-list">
                                <div className="form-list__block">
                                    {
                                        responseStringArray(suggestedGoals).map((value, index) => (
                                            <FormItemChoice
                                                key={index}
                                                type="checkbox"
                                                name="businessGoals"
                                                value={value}
                                                label={value}
                                                box
                                                constants={{currentState : selectedGoals, updateState : setSelectedGoals}}
                                            />
                                        ))
                                    }
                                </div>
                                <div className="form-list__block">
                                    {
                                        <FormItem
                                            value={customGoals}
                                            type={"text"}
                                            handleChange={(event) => setCustomGoals(event.target.value)}
                                            label={t('guide.businessGoals.additionalLabel')}
                                        />
                                    }
                                </div>
                            </div>
                        </GuideStep>
                        <Switcher stepHandle={{step, setStep}} />
                    </>
                }
            </>
        )
    }
    // Metrics
    else if (step == 4) {
        return(
            <>
                {!suggestedMetrics ?  <Loader /> :
                    <>
                        <GuideStep
                            submitHandle={stepNext}
                            headline={t('guide.metrics.headline')}
                            desc={t('guide.metrics.desc')}
                            subheadline={t('guide.subHeadlineGeneral')}
                            buttonReload={reloadBusinessMetrics}
                            buttonBackHandle={stepBack}
                            allowSubmitOn={customMetrics || selectedMetrics}
                            stepHandle={{step, setStep}}
                        >
                            <div className="form-list">
                                <div className="form-list__block">
                                    {
                                        responseStringArray(suggestedMetrics).map((value, index) => (
                                            <FormItemChoice
                                                key={index}
                                                type="checkbox"
                                                name="metrics"
                                                value={value}
                                                label={value}
                                                box
                                                constants={{currentState : selectedMetrics, updateState : setSelectedMetrics}}
                                            />
                                        ))
                                    }
                                </div>
                                <div className="form-list__block">
                                    {
                                        <FormItem
                                            value={customMetrics}
                                            type={"text"}
                                            handleChange={(event) => setCustomMetrics(event.target.value)}
                                            label={t('guide.metrics.additionalLabel')}
                                        />
                                    }
                                </div>
                            </div>
                        </GuideStep>
                        <Switcher stepHandle={{step, setStep}} />
                    </>
                }
            </>
        )
    }
    // Budget
    else if (step == 5) {
        return(
            <>
                <>
                    <GuideStep
                        submitHandle={stepNext}
                        headline={t('guide.budget.headline')}
                        desc={t('guide.budget.desc')}
                        buttonBackHandle={stepBack}
                        allowSubmitOn={budget}
                        stepHandle={{step, setStep}}
                    >
                        <div className="form-list">
                            <div className="form-list__block">
                                <FormItemBudget
                                    label1={t('guide.budget.label')}
                                    selectedBudget={budget}
                                    inputName={t('guide.budget.inputName')}
                                    minValue={t('guide.budget.from')}
                                    maxValue={t('guide.budget.to')}
                                    step={t('guide.budget.step')}
                                    notes={t('guide.budget.notes', { returnObjects: true })}
                                    valueHandle={{get: budget, set: setBudget}}
                                />
                            </div>
                        </div>
                    </GuideStep>
                    <Switcher stepHandle={{step, setStep}} />
                </>
            </>
        )
    }
    // Delivery date
    else if (step == 6) {
        return(
            <>
                <>
                    <GuideStep
                        submitHandle={stepNext}
                        headline={t('guide.deliveryDate.headline')}
                        desc={t('guide.deliveryDate.desc')}
                        buttonBackHandle={stepBack}
                        allowSubmitOn={deliveryDate}
                        stepHandle={{step, setStep}}
                    >
                        <div className="form-list">
                            <div className="form-list__block">
                                <FormItemTerm
                                    label1={t('guide.deliveryDate.label')}
                                    label2={deliveryDate}
                                    selectedDate={deliveryDate}
                                    selectedDays={deliveryDays}
                                    inputName={t('guide.deliveryDate.inputName')}
                                    minValue={t('guide.deliveryDate.from')}
                                    maxValue={t('guide.deliveryDate.to')}
                                    step={t('guide.deliveryDate.step')}
                                    notes={t('guide.deliveryDate.notes', { returnObjects: true })}
                                    valueHandle={{get: deliveryDate, set: calculateDeliveryDate}}
                                    notePreposition={t('guide.deliveryDate.notePreposition')}
                                />
                            </div>
                        </div>
                    </GuideStep>
                    <Switcher stepHandle={{step, setStep}} />
                </>
            </>
        )
    }
    // Send
    else if (step == 7) {
        return(
            <>
                <>
                    <GuideStep
                        submitHandle={submitData}
                        headline={t('guide.projectInfo.headline')}
                        buttonBackHandle={stepBack}
                        buttonSubmitToMake={true}
                        allowSubmitOn={{projectName, userEmail}}
                        stepHandle={{step, setStep}}
                    >
                        <div className="form-list">
                            <div className="form-list__block">
                                <h2 className="step__subheadline">{t('guide.projectInfo.subHeadlineNames')}</h2>
                                <FormItem
                                    type={"text"}
                                    value={projectName}
                                    label={t('guide.projectInfo.labelProjectName')}
                                    handleChange={(event) => setProjectName(event.target.value)}
                                    required={true}
                                />
                                <FormItem
                                    type={"text"}
                                    value={companyName}
                                    label={t('guide.projectInfo.labelCompanyName')}
                                    handleChange={(event) => setCompanyName(event.target.value)}
                                    required={true}
                                />
                                <FormItem
                                    type={"text"}
                                    value={personName}
                                    label={t('guide.projectInfo.labelPersonName')}
                                    handleChange={(event) => setPersonName(event.target.value)}
                                    required={true}
                                />
                            </div>
                            <div className="form-list__block">
                                <div>
                                    <h2 className="step__subheadline">{t('guide.projectInfo.subHeadlineEmail')}</h2>
                                    <p>{t('guide.projectInfo.emailDesc')}</p>
                                </div>
                                <FormItem
                                    type={"email"}
                                    value={userEmail}
                                    label={t('guide.projectInfo.labelEmail')}
                                    handleChange={(event) => setUserEmail(event.target.value)}
                                    required={true}
                                />
                            </div>
                        </div>
                    </GuideStep>
                    <Switcher stepHandle={{step, setStep}} />
                </>
            </>
        )
    }
    // ThankYouPage
    else {
        return(
            <>
                {!submittedToWebhook ? <Loader/> :
                    <>
                        <GuideStep
                            headline={t('guide.thankYouPage.headline')}
                            desc={t('guide.thankYouPage.desc')}
                        >
                            <ThankYouPage/>
                        </GuideStep>
                    </>
                }
            </>
        )
    }
}