import { Link } from "react-router-dom";
import {useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {useTranslation} from "react-i18next";

export default function ThankYouPage () {
    const {t} = useTranslation()
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/submitted')
    }, []);
    return (
        <div className="thankyou-page">
            <Link className="button button--primary" to={t('guide.thankYouPage.url')}>{t('guide.thankYouPage.button')}</Link>
        </div>
    )
};