export default function FormItem ({type, value, handleChange, label, required}) {
    return (
        <label className="form-item">
            <input
                className="form-item__entry"
                type={type} value={value}
                onChange={handleChange}
                required={required? "required": ""}
            />
            <div className="form-item__desc">{label}</div>
        </label>
    )
};