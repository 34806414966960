import {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";

export default function FormItemBudget (props) {
    const {t} = useTranslation()
    const [rangeValue, setRangeValue] = useState(props.valueHandle.get);
    const [label2, setLabel2] = useState(numberWithSpaces(props.selectedBudget));
    const [note, setNote] = useState("");
    const [progress, setProgress] = useState((props.valueHandle.get / props.maxValue) * 100)

    function handleNote() {
        props.notes.forEach(function(actualNote) {
            if(rangeValue > (props.maxValue/100) * actualNote.threshold) {
                setNote(actualNote.text);
            }
        });
    }

    useEffect(() => {
        handleNote();
    }, []);

    // make white space after every three digits
    function numberWithSpaces(x) {
        let formattedAmount = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        return `${formattedAmount} ${t('currency')}`;
    }

    function handleRangeChange(event) {
        let value = event.target.value;
        setProgress((value/props.maxValue) * 100);
        props.valueHandle.set(value);
        setRangeValue(value);
        setLabel2(numberWithSpaces(value));
        if(value == props.maxValue) {
            setLabel2(numberWithSpaces(value)+" +");
        };
        handleNote();
    }

    return (
        <div className="form-item-range">
            <div className="form-item-range__info">
                <strong className="form-item-range__label form-item-range__label--1">{props.label1}</strong>
                <strong className="form-item-range__label form-item-range__label--2">{label2}</strong>
            </div>
            <input
                className="form-item-range__entry"
                value={rangeValue}
                type="range"
                name={props.inputName}
                min={props.minValue}
                max={props.maxValue}
                step={props.step}
                onChange={handleRangeChange}
                style={{backgroundSize: progress+"% 100%"}}
            />
            <p className="form-item-range__desc">{note}</p>
        </div>
    )
};