export default function Switcher ({stepHandle}) {
    const handleClick = (buttonId) => {
        stepHandle.setStep(buttonId);
    };

    return (
        <div className="switcher switcher--steps">
            {(() => {
                const buttons = [];
                for (let i = 1; i <= 7; i++) {
                    buttons.push(
                        <button
                            className={`switcher__item${stepHandle.step == i ? " is-active" : ""}`}
                            type="button"
                            key={i}
                            onClick={(event) => handleClick(i)}
                        >
                        </button>
                    );
                }
                return buttons;
            })()}
        </div>
    )
};