import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Intro from "./components/guide/Intro";
import GuideSteps from "./components/guide/GuideSteps";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Intro />} />
                    <Route path="guide" element={<GuideSteps />} />
                    <Route path="submitted" element={<GuideSteps />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}


