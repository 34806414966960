import ProgressBar from "./ProgressBar";
import {useTranslation} from "react-i18next";

export default function GuideStep (props) {

    // translation
    const {t} = useTranslation()

    return (
        <section className="step">
            <form className={`step__form${props.allowSubmitOn ? "" : " is-invalid"}`} onSubmit={props.submitHandle} noValidate="novalidate">
                <div className="step__body">
                    <div className="step__top-block">
                        <h1 className="step__headline">{props.headline}</h1>
                        {props.desc && <p className="step__desc">{props.desc}</p>}
                    </div>
                    {props.subheadline && <h2 className="step__subheadline">{props.subheadline}</h2>}
                    {props.children}
                    {props.examples && <p className="step__examp">{props.examples}</p>}
                </div>

                <div className="step__footer">
                    {props.submitHandle &&
                        <>
                            <div className="step__buttons">
                                {props.buttonReload &&
                                    <a href="#" onClick={props.buttonReload}
                                        className="button button--secondary button--secondary-icon button--secondary-icon-reload button--step-reload"
                                        type="button"
                                    >
                                        {t('guide.buttonReloadGeneral')}
                                    </a>
                                }
                                {
                                    props.buttonBackHandle &&
                                    <button className="button button--secondary" type="button" onClick={props.buttonBackHandle}>
                                        {t('guide.buttonBackGeneral')}
                                    </button>
                                }
                                {
                                    props.submitHandle &&
                                    <button className="button button--primary button--step-submit" type="submit">
                                        {props.buttonSubmitToMake ? t('guide.finalStep.submitMake') : t('guide.buttonSubmitGeneral')}
                                    </button>
                                }
                            </div>
                        </>
                    }
                    <ProgressBar stepHandle={props.stepHandle} />
                </div>
            </form>
        </section>
    )
};