import {useState, useEffect } from "react";
import Menu from '../../components/Menu';
import {useTranslation} from "react-i18next";

export default function PageHeader() {

    // translations
    const {t} = useTranslation()

    // menu opening
    const [menuActive, setMenuActive] = useState(false);
    useEffect(() => {
        if(window.innerWidth >= 960) {
            setMenuActive(true);
        }
    }, []);
    function handleMenuToggle() {
        setMenuActive(menuActive ? false : true);
    }

    return (
        <header className="page-header">

            <a className="page-logo" href="/">
                <img className="page-logo__image" src="images/style/logo.svg" alt={t('appName')} />
            </a>

            <Menu menuActive={menuActive} />

            <button className={`menu-opener${menuActive ? " is-active" : ""}`} type="button" title="Skrýt nebo zobrazit menu" onClick={handleMenuToggle}>
                <span className="menu-opener__text">Menu</span>
                <span className="menu-opener__content menu-opener__content--1"></span>
                <span className="menu-opener__content menu-opener__content--2"></span>
            </button>

        </header>
    )
};