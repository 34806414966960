import {useTranslation} from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

export default function Menu({menuActive}){
    const { t, ready } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    // menu items import
    const menuItemsObj = t('menu', { returnObjects: true });
    const menuItems = menuItemsObj.map((item) =>
        <li className="menu__item menu__item--1" key={item.text}>
            <a className="menu__link menu__link--1" href={item.url} title={item.title} target="_blank" rel="noopener noreferrer">
                {item.text}
            </a>
        </li>
    );

    // localisation switch
    const lngs = {
        en: { nativeName: 'English'},
        cs: { nativeName: 'Česky'}
    };
    const {i18n} = useTranslation()
    const changeLanguage = (lng) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('lang', lng);
        navigate(`${location.pathname}?${searchParams.toString()}`);
    };

    return (

        <nav className={`menu${menuActive ? " is-active" : ""}`}>
            <ul className="menu__list menu__list--1">
                {menuItems}
                {Object.keys(lngs).map((lng) =>(
                    <li className={`menu__item menu__item--1 lng ${i18n.resolvedLanguage === lng ? 'disabled' : ''}`} key={lng}>
                        <a
                            className="menu__link menu__link--1"
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                changeLanguage(lng);
                            }}
                        >
                            {lngs[lng].nativeName}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    )
};