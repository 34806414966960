export default function FormItemChoice ({type, name, value, label, box, constants}) {

    let classes = "form-item-choice";
    if(type=="checkbox") {
        classes += " form-item-choice--checkbox";
    }
    if(type=="radio") {
        classes += " form-item-choice--radio";
    }
    if(box) {
        classes +=" form-item-choice--box";
    }

    let checked;

    // Set Checked State - preserves step state for going back through guide
    const setCheckedState = () => {
        if(name != "agreement") {
            if(constants.currentState.includes(value)) {
                checked = "checked";
            } else {
                checked = "";
            }
        }
    };

    setCheckedState();

    // Update Paired Constant
    const updatePairedConstant = (name) => {
        let values = "";
        if(name == "agreement") {
            const agreementCheckbox = document.querySelector("input[name=agreement]");
            if (agreementCheckbox.checked) {
                values = true;
            } else {
                values = false;
            }
        } else {
            const checkboxes = document.querySelectorAll("input[name="+name+"]");
            checkboxes.forEach(checkbox => {
                if (checkbox.checked) {
                    values += checkbox.value + ", ";
                } else {
                }
            });
        }
        if (typeof values === 'string' && values.endsWith(', ')) {
          values = values.slice(0, -2)
        }
        constants.updateState(values);
    };

    return (
        <label className={`${classes} ${checked ? 'checked' : ''} ${name==="agreement" ? 'agreement' : ''}`}>
            <input
                className="form-item-choice__entry"
                checked={checked}
                type={type}
                value={value}
                name={name}
                onChange={(event) => updatePairedConstant(event.target.name)}
            />
            <div className="form-item-choice__desc">{label}</div>
        </label>
    )
};

