import {useState, useEffect} from "react";

export default function FormItemTerm (props) {
    const [rangeValue, setRangeValue] = useState(props.selectedDays);
    const [label2, setLabel2] = useState(JSON.stringify(props.selectedDate));
    const [note, setNote] = useState("");
    const [progress, setProgress] = useState((props.selectedDays / props.maxValue) * 100)

    function handleNote() {
        props.notes.forEach(function(actualNote) {
            if(rangeValue > (props.maxValue/100) * actualNote.threshold) {
                setNote(actualNote.text);
            }
        });
    }

    useEffect(() => {
        handleNote();
    }, []);

    function handleRangeChange(event) {
        let value = event.target.value;
        setProgress((value/props.maxValue) * 100);
        props.valueHandle.set(value);
        setRangeValue(value);
        handleNote();
    }

    return (
        <div className="form-item-range">
            <div className="form-item-range__info">
                <strong className="form-item-range__label form-item-range__label--1">{props.label1}</strong>
                <strong className="form-item-range__label form-item-range__label--2">{`${props.selectedDate.getDate()}.${props.selectedDate.getMonth() + 1}.${props.selectedDate.getFullYear()}`}</strong>
            </div>
            <input
                className="form-item-range__entry"
                value={rangeValue}
                type="range"
                name={props.inputName}
                min={props.minValue}
                max={props.maxValue}
                step={props.step}
                onChange={handleRangeChange}
                style={{backgroundSize: progress+"% 100%"}}
            />
            <p className="form-item-range__desc">{props.notePreposition} <span className="form-item-range__days-counter">{props.selectedDays}</span> {note}</p>
        </div>
    )
};