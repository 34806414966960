import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Intro () {
    const {t} = useTranslation()
    return (
        <section className="intro">
            <h1 className="intro__headline">{t('intro.headline')}</h1>
            <p className="intro__desc">{t('intro.desc')}</p>
            <Link className="button button--primary" to="/guide">{t('intro.button')}</Link>
        </section>
    )
};