import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import PageHeader from "./PageHeader";
import "../../css/index.v2.scss";
import { Helmet } from 'react-helmet';

export default function Layout() {
    // translations
    const {t, i18n } = useTranslation();

    // adding lang parameter into url
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        let lang = query.get('lang');
        if(window.location.pathname == "/") {
            document.body.classList.add('body-homepage');
        } else {
            document.body.classList.remove('body-homepage');
        }
        // Adding lang parameter into url if it doesn't exist
        if (!lang) { //
            lang = i18n.language; // Get default language set in i18n
            query.set('lang', lang);
            const newUrl = location.pathname + '?' + query.toString();
            navigate(newUrl, { replace: true }); // Replaces the existing URL with the new URL without adding a new entry to the browser history
        } else {
            i18n.changeLanguage(lang);
        }

    }, [location, i18n]);

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>{t('webTitle')}</title>
                <meta name="description" content={t('webDescription')} />
                <meta property="og:description"  content={t('webDescription')} />
                <meta property="og:title" content={t('webTitle')} />
                <meta property="og:url" content={t('webUrl')} />
            </Helmet>
            <PageHeader />
            <main className="page-content">
                <Outlet />
                <img className="badge-wt100" src="images/style/badge-wt100.svg" alt={t('badgeWT100')} />
            </main>
        </div>
    )
}