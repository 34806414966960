export default function Loader () {
    return (
        <div className="loader">
            <div className="loader__list">
                <span className="loader__item"></span>
                <span className="loader__item"></span>
                <span className="loader__item"></span>
            </div>
        </div>
    )
};