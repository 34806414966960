import {useTranslation} from "react-i18next";

export default function ProgressBar ({stepHandle}) {
    const {t} = useTranslation()
    if(!stepHandle) {
        return;
    }
    const progress = (100/t('guide.steps')) * stepHandle.step;
    return (
        <div className="progress-bar progress-bar--steps" style={{backgroundSize: progress+"% 100%"}}></div>
    )
};